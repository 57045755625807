/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import type { Slot as SlotType } from '../../../types/slot.type';

const AVAILABILITY_SLICE = 'AVAILABILITY';

const initialState = {
  slots: [] as Array<any>,
  loadAvailability: false as boolean,
  appointments: [] as Array<any>,
};

export const availabilitySlice = createSlice({
  name: AVAILABILITY_SLICE,
  initialState,
  reducers: {
    setAppointments: (state, action) => {
      state.appointments = action.payload;
    },
    setLoadAvailability: (state, action) => {
      state.loadAvailability = action.payload;
    },
    setSlots: (state, action) => {
      state.slots = action.payload;
    },
    editSlots: (state, action) => {
      action.payload.forEach((newSlot: SlotType) => {
        state.slots = state.slots.map((slot: SlotType) => (newSlot.id === slot.id ? { ...slot, ...newSlot } : slot));
      });
    },
    removeSlot: () => {},
    addSlots: () => {},
    fetchAppointments: () => {},
    fetchSlots: () => {},
  },
});

export const {
  setAppointments,
  fetchAppointments,
  setLoadAvailability,
  setSlots,
  fetchSlots,
  addSlots,
  removeSlot,
  editSlots,
} = availabilitySlice.actions;

export const availabilitySliceSelector = (state: any) => state.availability;

export default availabilitySlice.reducer;
