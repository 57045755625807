/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const TASKS_SLICE = 'TASKS';

const initialState = {
  loadTasks: false,
  taskRequests: [],
  taskAppointments: [],
};

export const tasksSlice = createSlice({
  name: TASKS_SLICE,
  initialState,
  reducers: {
    setLoadTasks: (state, action) => {
      state.loadTasks = action.payload;
    },
    setTaskRequests: (state, action) => {
      state.taskRequests = action.payload;
    },
    setTaskAppointments: (state, action) => {
      state.taskAppointments = action.payload;
    },
    fetchTasks: () => {}, // call watcherSaga.
  },
});

export const {
  setLoadTasks,
  setTaskRequests,
  setTaskAppointments,
  fetchTasks,
} = tasksSlice.actions;

export const tasksSliceSelector = (state: any) => state.tasks;

export default tasksSlice.reducer;
