/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { PreparationListItem } from '../../types';

const PATIENT_SLICE = 'PATIENTOVERVIEW';

const initialState = {
  patientInfo: {} as any,
  doctorsComments: [] as Array<any>,
  documents: [] as Array<any>,
  patientFile: {},
  preparations: [] as Array<any>,
  loadPatient: false as boolean,
  furAppointments: [] as Array<any>,
  chosenAvailablePrescription: 0 as number,
  chosenPreparationListItem: [] as PreparationListItem[],
};

export const patientSlice = createSlice({
  name: PATIENT_SLICE,
  initialState,
  reducers: {
    setPatientInfo: (state, action) => {
      state.patientInfo = action.payload;
    },
    setLoadPatient: (state, action) => {
      state.loadPatient = action.payload;
    },
    setFurAppointments: (state, action) => {
      state.furAppointments = action.payload;
    },
    setDoctorsComments: (state, action) => {
      state.doctorsComments = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setPreparations: (state, action) => {
      state.preparations = action.payload;
    },
    setFile: (state, action) => {
      state.patientFile = action.payload;
    },
    setChosenAvailablePrescription: (state, action) => {
      state.chosenAvailablePrescription = action.payload;
    },
    setChosenPreparationListItem: (state, action) => {
      state.chosenPreparationListItem = action.payload;
    },
    setDeclinePatientInfo: (state) => {
      const declinedPatientinfo = {
        ...state.patientInfo,
        availableFollowUpPrescription: 0,
        isPatientDeclined: true,
      };
      state.patientInfo = declinedPatientinfo;
    },
    fetchPatientInfo: () => {},
    fetchDoctorsComments: () => {},
    fetchDocuments: () => {},
    fetchPreparations: () => {},
    fetchPatientFile: () => {},
    declinePatient: () => {},
    fetchFurAppointments: () => {},
  },
});

export const {
  setPatientInfo,
  setDoctorsComments,
  setDocuments,
  setFurAppointments,
  fetchPatientInfo,
  fetchFurAppointments,
  fetchDoctorsComments,
  fetchPreparations,
  fetchDocuments,
  fetchPatientFile,
  setLoadPatient,
  setPreparations,
  setFile,
  setChosenAvailablePrescription,
  setChosenPreparationListItem,
  setDeclinePatientInfo,
  declinePatient,
} = patientSlice.actions;

export const patientSliceSelector = (state: any) => state.patientOverview;

export default patientSlice.reducer;
