/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import classNames from 'classnames/bind';
import type { DoctorAppointments } from '../../../types';
import { Button, CollapsibleCard } from '../../atoms';
import style from './AppointmentItems.module.scss';

const cx = classNames.bind(style);

const TextLabel = cx('TextLabel');
const TextBold = cx('TextBold');
const RowContainer = cx({ AppointmentItemsRowContainer: true });
const ColumnContainer = cx({ AppointmentItemsColumnContainer: true });
const UnderlinedButton = cx({ AppointmentItemsUnderlinedButton: true });

interface NextAppointmentHeaderProps {
  appointment: DoctorAppointments;
  appointmentType: any;
  isAppointmentUpdated: boolean;
}

export const NextAppointmentHeader = ({
  appointment,
  appointmentType,
  isAppointmentUpdated,
}: NextAppointmentHeaderProps) => {
  const { toggleExpanded, closeAccordion } = CollapsibleCard.useCollapse();

  useEffect(() => {
    if (closeAccordion) {
      closeAccordion();
    }
  }, [isAppointmentUpdated]);

  const hasData = () => (
    'notes' in appointment || 'prescription' in appointment || 'todos' in appointment || 'carriedOut' in appointment
  );

  return (
    <div className={RowContainer}>
      <div className={ColumnContainer}>
        <span className={TextLabel}>Datum</span>
        <span className={TextBold}>
          {new Date(appointment.startDate).toLocaleString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </span>
      </div>
      <div className={ColumnContainer}>
        <span className={TextLabel}>Uhrzeit</span>
        <span className={TextBold}>
          {new Date(appointment.startDate).toLocaleString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </span>
      </div>
      <div className={ColumnContainer}>
        <span className={TextLabel}>Typ</span>
        <span className={TextBold}>{appointmentType}</span>
      </div>
      <div className={ColumnContainer}>
        <span className={TextLabel}>Ort</span>
        {appointment.type === 1 && <span className={TextBold}>Online</span>}
        {appointment.type === 2 && <span className={TextBold}>{appointment?.practice?.name}</span>}
      </div>
      <div className={ColumnContainer}>
        <span className={TextLabel}>Arzt</span>
        <span className={TextBold}>{`${appointment?.doctorTitle} ${appointment?.doctorName}`}</span>
      </div>
      <div className={ColumnContainer}>
        <span className={TextLabel}>Akte/Status</span>
        <Button className={UnderlinedButton} type='secondary' onClick={toggleExpanded}>
          {hasData() ? 'bearbeiten' : 'anlegen'}
        </Button>
      </div>
    </div>
  );
};
