/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import 'moment/locale/de';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import type { DoctorAppointments } from '../../types';
import style from './Dashboard.module.scss';
import { fetchTasks, tasksSliceSelector } from './dashboardSlice';
import { Button, Icon, Loader } from '../../components/atoms';
import { setIsMainOrSubDoctor } from '../../app/appSlice';
import { availabilitySliceSelector, fetchAppointments } from '../profile/availability/availabilitySlice';

const cx = classNames.bind(style);

export function Dashboard(): JSX.Element {
  const DashboardClasses = cx('container', { Dashboard: true });
  const dispatch = useDispatch();
  const { taskRequests, taskAppointments } = useSelector(tasksSliceSelector);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateAppointments, setSelectedDateAppointments] = useState<DoctorAppointments[]>([]);
  const [dateText, setDateText] = useState('');
  const [currentPage, setCurrentPage] = useState('Appointments');
  const { appointments, loadAvailability } = useSelector(availabilitySliceSelector);
  const navigate = useNavigate();

  const showPatientinfoComponent = (patientId: any, page: string) => {
    navigate(`/patients/${page}/${patientId}`);
  };

  const selectedAppointments = () => {
    const newArrayOfAppointments = appointments.filter(
      (element: DoctorAppointments) => new Date(element.startDate).toDateString() === new Date(selectedDate).toDateString(),
    );
    setSelectedDateAppointments(newArrayOfAppointments);
  };

  const nextDay = () => {
    const day = new Date(selectedDate);
    const newDay = day.setDate(day.getDate() + 1);
    setSelectedDate(new Date(newDay));
  };

  const prevDay = () => {
    const day = new Date(selectedDate);
    const newDay = day.setDate(day.getDate() - 1);

    setSelectedDate(new Date(newDay));
  };

  const checkMinForAppointment = (element: DoctorAppointments) => {
    const date = new Date(element.startDate);
    const now = new Date();

    if (date.toDateString() === now.toDateString()) {
      if (date > now) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const diffTime = Math.abs(date - now);
        const diffMin = diffTime / 60000;

        if (diffMin < 11) {
          return diffMin;
        }
      }
    }

    return 11;
  };

  const checkVideoButtonDisplay = (element: DoctorAppointments) => {
    const start = new Date(element.startDate);
    const end = new Date(element.endDate);
    const now = new Date();
    if (element.type !== 1) {
      return false;
    }
    if (start.toDateString() !== new Date().toDateString()) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const diffTime = Math.abs(start - now);
    const diffMin = diffTime / 60000;
    if (diffMin < 11 && start > now) {
      return true;
    }

    const isTakingPlace = start < now && end > now;
    if (isTakingPlace) {
      return true;
    }

    return false;
  };

  const getDateText = () => {
    const date: string = new Date(selectedDate).toLocaleString('de-DE', {
      weekday: 'short',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });

    const newString = date.slice(0, 2) + date.slice(3);
    setDateText(newString);
  };

  useEffect(() => {
    dispatch(fetchTasks());

    const payload: any = { future: false };
    dispatch(fetchAppointments(payload));
  }, []);

  useEffect(() => {
    getDateText();
    selectedAppointments();
  }, [selectedDate]);

  useEffect(() => {
    selectedAppointments();
  }, [loadAvailability]);

  useEffect(() => {
    // Force rerender every 1/2 minute
    const MINUTE_MS = 30000;
    const interval = setInterval(() => {
      setSelectedDateAppointments((app: DoctorAppointments[]) => [...app]);
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const renderSelectedDateAppointments = () => (
    selectedDateAppointments
    && (selectedDateAppointments.map((element: DoctorAppointments, index: number) => {
      const diffMin = checkMinForAppointment(element);
      const getAppointmentType = (appointmentType: number | undefined) => {
        switch (appointmentType) {
        case 1:
          return 'Erstgespräch';
        case 2:
          return 'Wiedervorstellung';
        case 3:
          return 'Beratung';
        default:
          return '';
        }
      };

      return (
        <div className={style.AppointmentsContainers} key={index}>
          <div className={style.AppointmentsRow1}>
            <div>
              <p className={style.AppointmentTimeNameText}>
                {new Date(element.startDate).toLocaleString('de-DE', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </p>
              {element.type === 1 && <p className={style.AppointmentTypeText}>Online</p>}
              {element.type === 2 && <p className={style.AppointmentTypeText}>Persönlich</p>}
            </div>
            <div>
              <p className={style.AppointmentTimeNameText}>{element?.patientName}</p>
              <p className={style.AppointmentText}>{getAppointmentType(element.appointmentType)}</p>
              {checkVideoButtonDisplay(element) && (
                <Button
                  children={'Zur Videosprechstunde'}
                  type={'primary'}
                  className={style.AppointmentsButtonClass}
                  onClick={() => {
                    window.open(`https://esprechstunde.net/termin/${element.esprechstundeCode}`, '_blank');
                  }}
                ></Button>
              )}
            </div>
            <div className={diffMin < 11 ? style.DetailsContainerNoClock : style.DetailsContainer}>
              {diffMin < 11 && (
                <div className={style.ClockContainer}>
                  <Icon icon={'clock'} size={19} color={'rgb(251, 176, 59)'} />
                  <p>in {Math.trunc(diffMin)} Min.</p>
                </div>
              )}
              <Button
                children={'Details'}
                type={'primary'}
                className={style.ButtonClass}
                onClick={() => { showPatientinfoComponent(element.patientId, currentPage); }}
              />
            </div>
          </div>
        </div>
      );
    }))
  );

  const renderTaskRequests = () => (
    (taskRequests
                && taskRequests.length !== 0)
                && taskRequests.map((element: DoctorAppointments) => (
                  <div className={style.TasksContainer} key={element.id}>
                    <div>
                      <p>{element.patientName}</p>
                    </div>
                    <div>
                      <p>Folgerezept angefordert</p>
                    </div>
                    <Button
                      children={'Details'}
                      type={'primary'}
                      className={style.ButtonClass}
                      onClick={() => { showPatientinfoComponent(element.patientId, currentPage); setCurrentPage('FollowRecipe'); }}
                    />
                  </div>
                ))
  );

  const renderTaskAppointments = () => (
    taskAppointments
                && taskAppointments.length !== 0
                && taskAppointments.map((element: DoctorAppointments) => (
                  <div className={style.TasksContainer} key={element.id} style={!element.isMainOrSubDoctor ? { borderColor: '#f56e6e', borderWidth: 2, borderStyle: 'solid' } : {}}>
                    <div>
                      <p>{element.patientName}</p>
                    </div>
                    <div>
                      <p>Terminstatus setzen</p>
                    </div>
                    <Button
                      children={'Details'}
                      type={'primary'}
                      className={style.ButtonClass}
                      onClick={() => { showPatientinfoComponent(element.patientId, currentPage); dispatch(setIsMainOrSubDoctor(element.isMainOrSubDoctor)); }}
                    />
                  </div>
                ))
  );

  return (
    <div className="details_container">
      <div className={DashboardClasses}>
        {loadAvailability ? <Loader /> : (
          <div>
            <div className={style.MainContainer}>
              <div className={style.ColumnContainer}>
                <> <div className={style.AppointmentsTitleContainer}>
                  <div className={style.CalendarContainer}>
                    <Icon icon={'calendar'} size={80} />
                    <p className={style.CalendarDay}>
                      {new Date(selectedDate).toLocaleString('de-DE', {
                        day: '2-digit',
                      })}
                    </p>
                  </div>
                  <div>
                    <p className={style.MyApointmentsHeader}><FormattedMessage id='dashboard.Appointments' /></p>
                    <p className={style.DateHeader}>{dateText}</p>
                  </div>
                  <div className={style.RowContainer}>
                    <div>
                      <button className={style.ArrowButton} onClick={prevDay}>
                        <Icon icon={'chevron'} size={25} />
                      </button>
                    </div>
                    <div>
                      <button className={style.ArrowButton} onClick={nextDay}>
                        <Icon icon={'chevron'} size={25} />
                      </button>
                    </div>
                  </div>
                </div>
                {renderSelectedDateAppointments()}
                </>
              </div>
              <div className={style.ColumnContainer}>
                <>
                  <div className={style.TasksTitleContainer}>
                    <Icon icon={'checklist'} size={78} />
                    <div>
                      <p><FormattedMessage id='dashboard.Tasks' /></p>
                    </div>
                  </div>
                  {renderTaskRequests()}
                  {renderTaskAppointments()}
                </>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
