/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

export class UserService {
  static async login(data: { email: string; password: string }): Promise<Response> {
    const stayLoggedIn = false;

    return new Promise((resolve) => {
      axios
        .post('/user/authenticate', { stayLoggedIn, ...data })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async forgotPassword(data: { email: string }): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/forgot-password', data)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async resetPassword(data: { token: string; password: string }): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/reset-password', data)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getPatients(doctorId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/patient/doctor/${doctorId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getPatient(patientId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/patient/${patientId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorsComments(patientId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/patient/${patientId}/remark`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAppointments(future = false): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/appointment?future=${future}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDocuments(patientId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/patient/${patientId}/file`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getFile(fileId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/document/${fileId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async getDoctorTasks(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/doctor/tasks', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorTaskRequests(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/doctor/tasks-requests', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorTaskAppointments(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/doctor/tasks-appointments', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorData(doctorId: number | undefined): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`doctor/${doctorId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getPreparation(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/preparation', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async setMedication(data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/medication', data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async availableFollowupPrescription(patientId: number, data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put(`/patient/${patientId}/available-followup-prescription`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async followupPrescriptionRequest(requestId: number, data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put(`/followup-prescription-request/${requestId}/status`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async setAppointmentStatus(appointmentId: number, data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put(`/appointment/${appointmentId}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getSlots(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('doctor/slot', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async createSlot(data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('doctor/slot', data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async updateSlot(slotId: string, data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put(`doctor/slot/${slotId}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async deleteSlot(slotId: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .delete(`doctor/slot/${slotId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async declinePatient(patientId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put(`doctor/${patientId}/decline-patient`, {}, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
